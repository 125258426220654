import { attributesSetter } from '../attributesSetter';

const FB_SDK_SCRIPT_ID = 'facebook-jssdk';
const FB_SDK_SCRIPT_URL = '//connect.facebook.net/en_US/sdk.js';

export const createFbSDKScriptEl = (src: string = FB_SDK_SCRIPT_URL) => {
  if (document.getElementById(FB_SDK_SCRIPT_ID)) {
    return;
  }

  const fbSdcScriptEl = document.createElement('script');
  attributesSetter(fbSdcScriptEl, {
    id: FB_SDK_SCRIPT_ID,
    src,
    crossorigin: 'anonymous',
    async: 'async',
    defer: 'async',
  });

  document.body.append(fbSdcScriptEl);
};
